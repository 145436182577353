@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.question-query {
  margin-top: 1rem;
}
.container {
  min-height: max-content;
  .answerBody {
    line-height: 1.5rem !important;
    & > :first-child {
      padding-top: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1rem;
    }
  }
  .actionButton {
    color: $blue-60;
    font-family: $ae-font-family-inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    border: none;
    outline: none;
    background-color: transparent;
  }
}
.lastAnswer {
  min-height: calc(100dvh - 210px);
}
