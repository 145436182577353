@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.ratingContainer {
  padding: 0.75rem;
  border-radius: $primary-border-radius;
  margin-top: 0.25rem;
  background-color: $viking-00;
  &:hover {
    background-color: $slate-00;
  }
  .finalMessage {
    color: $slate-80;
    font-family: Inter;
    font-size: $primary-font-size;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 133.333% */
    display: flex;
    justify-content: center;
    .icon {
      color: $viking-70;
      margin-right: 0.4rem;
    }
  }
  :global(.ae-icon) {
    color: $viking-70;
    margin-right: 0.5rem;
  }
  .rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ratingHeader {
      display: flex;
      align-items: center;
      color: $slate-80;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1rem;
    }
    .ratingScale {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 0.5rem;

      .ratingButton {
        font-size: 1rem;
        width: 2rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        outline: none;
        border-radius: 0.5rem;
        background-color: $viking-10;
        color: #121212;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        .dotMask {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:hover {
          background-color: $viking-70;
          color: #ffffff;
        }
      }
      .selected {
        background-color: $viking-70;
        color: #ffffff;
      }
    }
  }

  .feedbackForm {
    margin-top: 0.5rem;

    .feedbackInput {
      width: 100%;
      height: 5rem;
      border: 1px solid #ff5555;
      border-radius: 5px;
      padding: 0.5rem;
      font-size: 1rem;
      margin: 0.5rem 0;
    }

    .submitButton {
      border-radius: 0.5rem;
      background: $viking-90;
      color: $slate-00;
      padding: 0.75rem 1rem;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */
      border: none;
      outline: none;
      &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
      }
    }
  }
  .notificationStyleOverride {
    margin: 1rem 0;
  }
}
.container {
  background-color: $slate-00;
}
