@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.container span {
  color: $gray-rd-700;
  text-align: right;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-regular;
  line-height: 1.25rem;
  margin: 0;
  cursor: pointer;
}
