@import "@aptedge/lib-ui/src/styles/mixins";

.preview-container {
  min-height: toRem(300px);
  visibility: visible;
  display: block;
  overflow: visible;
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;
  .session-replay-player {
    box-shadow: none !important;
  }
  :global(.rr-player) {
    box-shadow: none;
  }
}
