@import "@aptedge/lib-ui/src/styles/_colors";

.errorContainer {
  display: flex !important;
  background-color: $red-20;
  color: $red-60;
  border: 1px solid $red-00;
}
.successContainer {
  display: flex !important;

  background-color: $green-20;
  color: $green-60;
  border: 1px solid $green-00;
}
.baseNotification {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem;

  border-radius: 0.25rem;
  margin-bottom: 0;
  .left {
    display: flex;
    align-items: center;
    span {
      margin-left: 0.25rem;
    }
  }
  .closeBtn {
    cursor: pointer;
  }
}
