@import "@aptedge/lib-ui/src/styles/_mixins";

.login-content {
  .login-btn {
    padding: toRem(8px) toRem(10px) !important;
    box-shadow: rgb(51 51 51 / 8%) 0px 1px 4px;
  }
  .header {
    font-size: toRem(16px);
    color: #333;

    h3 {
      font-weight: 500;
    }
  }
  .divider {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: toRem(8px) toRem(0px);
    cursor: default;
  }
  .divider:before,
  .divider:after {
    content: "";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.35);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: toRem(0px) toRem(8px);
  }

  .ms-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: toRem(4px);
    box-sizing: border-box;
    border: 1px solid transparent;
    background: white;
    border: 1px solid #ddd;
    box-shadow: rgb(51 51 51 / 8%) 0px 1px 4px;
    color: rgb(51, 51, 51);
    max-width: 100%;
    padding: toRem(8px) toRem(10px);
    cursor: pointer;
    position: relative;
    margin-bottom: 1rem;
    transition: background 200ms;

    &:hover {
      background: #f8f8f8;
    }

    .oauth-icon {
      position: absolute;
      left: toRem(10px);
    }
    span {
      padding: 0.1rem 0;
    }
  }

  .oneloginIcon {
    width: 20px;
    height: 20px;
  }

  .oktaIcon {
    width: 20px;
    height: 18px;
  }
}
