.search-pagination-bar-container {
  padding-right: 4rem;
}

.global-search-results {
  margin-top: -0.5rem;
}

.unified-global-search-results {
  width: 45%;
}
