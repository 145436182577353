.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1rem;
}

.manageAllContainer {
  padding-right: 0.75rem;
}
