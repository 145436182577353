@import "@aptedge/lib-ui/src/styles/_colors";

.loader {
  width: 0.8rem;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 1rem 0 $action-button-color, -1rem 0 $loader-light-color;
    background: $action-button-color;
  }
  33% {
    box-shadow: 1rem 0 $action-button-color, -1rem 0 $loader-light-color;
    background: $loader-light-color;
  }
  66% {
    box-shadow: 1rem 0 $loader-light-color, -1rem 0 $action-button-color;
    background: $loader-light-color;
  }
  100% {
    box-shadow: 1rem 0 $loader-light-color, -1rem 0 $action-button-color;
    background: $action-button-color;
  }
}
