@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.container {
  .header {
    background-color: $viking-90;
    padding: 1rem;
    padding-top: 0;
    h1 {
      color: $viking-00;
      font-family: $ae-font-family-inter;
      font-size: 1.25rem;
      font-style: $ae-font-style-normal;
      font-weight: $fw-600;
      line-height: 1.5rem;
    }
    p {
      color: $viking-00;
      font-family: $ae-font-family-inter;
      font-size: 0.75rem;
      font-style: $ae-font-style-normal;
      font-weight: $fw-400;
      line-height: 1rem;
      margin-bottom: 0;
    }
  }
  .notificationBlock {
    margin: 1rem;
  }
  .form {
    padding: 1rem;
    display: grid;
    gap: 0.5rem 0;
    .field {
      display: grid;
      label {
        margin-bottom: 0.25rem;
        color: $slate-60;
        font-family: $ae-font-family-inter;
        font-size: 0.75rem;
        font-style: $ae-font-style-normal;
        font-weight: $fw-400;
        line-height: 1rem;
      }
      input,
      textarea {
        border-radius: 0.25rem;
        border: 1px solid $slate-20;
        background: $slate-10;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
      }
    }
    .switch {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        margin-left: 0.5rem;
        color: $slate-90;
        font-family: $ae-font-family-inter;
        font-size: 0.875rem;
        font-style: $ae-font-style-normal;
        font-weight: $fw-400;
        line-height: 1.25rem;
      }
    }
    button {
      border-radius: 0.5rem;
      background: $viking-80;
      padding: 0.75rem 1rem;
      color: $viking-00;
      width: max-content;
      margin-top: 1rem;
      border: none;
      outline: none;
      &:disabled {
        background: $slate-20;
        color: $slate-60;
      }
    }
  }
  .sessionReplayPreviewContainer {
    margin-top: 1rem;
  }
  .preview {
    padding: 1rem;
    padding-top: 0;
  }
}
