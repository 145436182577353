@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.container {
  .questionQueryTitle {
    font-size: 1.125rem;
    margin-bottom: 1rem;

    @include textEllipsis(2);
  }
  .aiAnswerContainer > :not(:first-child) {
    margin-top: 1.5rem;
    padding-top: 2.5rem;
  }
}
