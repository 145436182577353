@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";

.container {
  border-radius: $border-radius-rounded;
  border: 1px solid $gray-rd-200;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 53rem;
  padding: 4rem;
  font-family: Inter;
}

.filterIcon {
  width: 4rem;
  height: 4rem;
  color: $gray-rd-200;
}

.header {
  color: $gray-1;
  font-size: $ae-font-size-xl;
  font-weight: $ae-font-weight-medium;
  line-height: 2rem;
  padding-bottom: 1.5rem;
}

.description {
  color: $gray-1;
  font-size: $ae-font-size-medium;
  font-weight: $ae-font-weight-regular;
  line-height: 1.5rem;
}

.actionsContainer {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .selectContent {
    width: 22.5rem;
    z-index: 1;
    background: $white;
    border: 1px solid $slate-30;
    border-radius: $border-radius-standard;
    max-height: 20rem;
    overflow: auto;
  }

  .dropdownContainer {
    display: flex;
    align-items: center;
    width: 22.5rem;
    border: 1px solid $slate-30;
    border-radius: $border-radius-rounded;
    padding: 0.25rem 0.75rem;
    justify-content: space-between;
    margin: 0.25rem 0;
    cursor: pointer;
  }

  .dropdownItem {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    align-items: center;

    .itemLabel {
      gap: 0.75rem;
      display: flex;
      align-items: center;

      .pageInfoIcon {
        color: $slate-40;
      }

      .filterLabel {
        color: $gray-rd-800;
        font-size: $ae-font-size-reg;
        font-family: $ae-font-family-inter;
        font-weight: $ae-font-weight-regular;
      }
    }
  }

  .searchUser {
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid $slate-30;
    position: sticky;
    top: 0;
    z-index: 10;
    background: $white;

    .userInput {
      font-size: $ae-font-size-reg;
      font-family: $ae-font-family-inter;
      font-weight: $ae-font-weight-regular;
      color: $gray-rd-800;

      &::placeholder {
        color: $slate-60;
      }
    }

    .searchIcon {
      color: $slate-60;
      font-size: 1.25rem;
    }

    .clearIcon {
      cursor: pointer;
      color: $slate-60;
      font-size: 1rem;
    }
  }

  .fieldMessage {
    text-align: center;
    background: $slate-20;
    color: $gray-1;
    font-size: $ae-font-size-xs;
    font-family: $ae-font-family-inter;
    font-weight: $ae-font-weight-regular;
    transition: all 0.5s ease-in;
    transform: translateY(0px);
  }

  .dropdownPlaceholder {
    font-size: $ae-font-size-reg;
    font-family: $ae-font-family-inter;
    font-weight: $ae-font-weight-regular;
    color: $gray-rd-800;
  }

  .saveButton {
    background: $teal;
    border: 1px solid $teal-rd-700;
    border-radius: $border-radius-standard;
    padding: 0.5rem 1rem;
    width: 4.625rem;
    color: $white;
    margin-top: 1rem;
    cursor: pointer;

    &:disabled {
      opacity: 0.8;
      color: $slate-30;
    }

    &:hover {
      filter: brightness(80%);
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.confirmationContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 30rem;
  width: 30rem;
  padding: 1rem;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-rounded;
  height: 10rem;
  z-index: 901;
  position: fixed;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);

  .confirmationHeader {
    font-size: $ae-font-size-medium;
    font-weight: $ae-font-weight-semi-bold;
    font-family: $ae-font-family-inter;
    color: $gray-2;
  }

  .confirmationBody {
    font-size: $ae-font-size-medium;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family-inter;
    color: $gray-2;
  }

  .confirmationActions {
    display: flex;
    gap: 0.5rem;
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-medium;

    .confirm {
      background: $viking-1;
      color: $white;
      border-radius: $border-radius-standard;
      padding: 0.25rem 0.5rem;
      cursor: pointer;

      &:hover {
        filter: brightness(80%);
      }
    }

    .cancel {
      background: $white;
      border-radius: $border-radius-standard;
      border: 1px solid $gray-rd-200;
      padding: 0.25rem 0.5rem;
      cursor: pointer;

      &:hover {
        filter: brightness(80%);
      }
    }
  }
}
