@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.saveButton {
  all: unset;
  appearance: none;
  margin: 0;
  cursor: pointer;
  display: flex;
  width: 8.25rem;
  padding: 0.5rem 1.125rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid $teal-rd-700;
  background: $teal;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: $white;
  font-family: $ae-font-family-inter;
  font-size: 1rem;
  font-style: $ae-font-style-normal;
  font-weight: $ae-font-weight-semi-bold;
  line-height: 1.5rem;
  margin-top: 1rem;
}

.saveButton:hover {
  filter: brightness(80%);
}

.saveButton:disabled {
  background: $teal-rd-200;
  border: 1px solid $teal-rd-300;
}
