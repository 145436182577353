@import "@aptedge/lib-ui/src/styles/_mixins";
@import "@aptedge/lib-ui/src/styles/_typography";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_boxes.scss";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.confirmationContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 30rem;
  width: 30rem;
  padding: 1rem;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-rounded;
  height: 10rem;
  z-index: 901;
  position: fixed;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);

  .confirmationHeader {
    font-size: $ae-font-size-medium;
    font-weight: $ae-font-weight-semi-bold;
    font-family: $ae-font-family-inter;
    color: $gray-2;
  }

  .confirmationBody {
    font-size: $ae-font-size-medium;
    font-weight: $ae-font-weight-medium;
    font-family: $ae-font-family-inter;
    color: $gray-2;
  }

  .confirmationActions {
    display: flex;
    gap: 0.5rem;
    font-size: $ae-font-size-reg;
    font-weight: $ae-font-weight-medium;

    .confirm {
      background: $viking-1;
      color: $white;
      border-radius: $border-radius-standard;
      padding: 0.25rem 0.5rem;
      cursor: pointer;

      &:hover {
        filter: brightness(80%);
      }
    }

    .cancel {
      background: $white;
      border-radius: $border-radius-standard;
      border: 1px solid $gray-rd-200;
      padding: 0.25rem 0.5rem;
      cursor: pointer;

      &:hover {
        filter: brightness(80%);
      }
    }
  }
}
