@import "@aptedge/lib-ui/src/styles/_boxes";
@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_mixins";

.suggested-answer-container {
  color: #475467;
  font-weight: 600;
  background-color: $teal-lighter;
  padding: 0.75em;
  border-left: 0.25em solid #077789;
  border-radius: 0.2rem;
  font-family: Inter;
  position: relative;

  .question-query {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .answer-link {
    background: #f0f4ff;
    border-radius: 8px;
    color: #1570ef;
    font-size: small;
    cursor: pointer;
    padding: 4px 10px;
    margin: 4px 0;

    .icon {
      padding: unset;
      font-size: unset;
      margin-right: toRem(4px);
    }
  }
  .external-links {
    text-decoration-line: underline;
    color: #475467 !important;
  }
  .suggested-answer-header-skeleton {
    display: flex;
    justify-content: space-between;
    .button1 {
      margin: 0 1rem;
    }
  }

  .suggested-answer-body {
    // This font size is applied in pixels (px) and not in rems (root em units) solely to ensure consistency
    // in the appearance of the answer body content across both the web app and support app.
    font-size: 13px;
    color: #475467;
    margin-bottom: 0;
    word-break: break-word;
    a {
      color: #4f7ab7;
      font-size: small;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-top: 8px;
      color: #475467;
    }
  }

  .suggested-answer-feedback-button {
    color: $gray-600;
    border-radius: $border-radius-standard;
    .active {
      cursor: not-allowed;
    }
    &:focus {
      outline: none;
    }
    .icon {
      background-color: transparent;
      padding: 0;
      svg {
        path {
          stroke: #667085;
        }
      }
    }
  }
  .disabled {
    cursor: not-allowed;
  }
  .suggested-answer-feedback-button-approval-selected {
    .icon {
      svg {
        path {
          stroke: #069b5a;
        }
      }
    }
  }
  .suggested-answer-feedback-button-disapproval-selected {
    .icon {
      svg {
        path {
          stroke: #d22;
        }
      }
    }
  }

  .answer-sources {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding-top: 0.5rem;
    border-top: 1px solid #eaecf0;

    .answer-sources-list {
      margin-left: 0.5rem;
      display: flex;
      flex-wrap: wrap;

      .answer-source {
        display: flex;
        align-items: center;
        margin-right: toRem(12px);
        width: max-content;
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: toRem(12px);
          line-height: 140%;
          margin-left: toRem(0px);
          margin-top: toRem(4px);
          color: #1570ef;
          @include textEllipsis(1, 140px, left);
          cursor: pointer;
        }
      }
      .issues-icon {
        margin: auto;
        display: flex;
        align-items: center;
        padding-top: toRem(8px);
      }
      a {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: toRem(12px);
        line-height: 140%;
        margin-left: toRem(0px);
        margin-top: toRem(4px);
        color: #1570ef;
        @include textEllipsis(1, 180px, left);
        max-width: max-content;
      }
    }
  }
  .feedback-modal {
    display: grid;
    position: absolute;
    width: max-content;
    margin-top: toRem(8px);
    top: 2.4rem;
    right: 0px;
    z-index: 999;
    border-radius: toRem(12px);
    border: 1px solid #f5f5f5;
    box-shadow: 0px 0px toRem(40px) rgba(0, 0, 0, 0.1);
    padding: 1rem;
    background-color: white;
    width: toRem(342px);
    @include onSupportApp {
      top: 5.5rem;
      width: 100%;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: toRem(20px);
      margin-bottom: 1rem;
      color: #344054;
    }
    .description-input {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: toRem(8px);
      padding: toRem(8px);
    }
    .check {
      visibility: hidden;
    }
    .detailed-feedback {
      display: flex;
      align-items: center;
      margin: toRem(8px);
      label {
        margin-block-end: 0;
        margin-inline-start: toRem(8px);
        cursor: pointer;

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: toRem(12px);
        line-height: toRem(18px);
        /* identical to box height, or 150% */

        color: #667085;
      }
      .checked-label {
        color: #077789;
      }
      .check-square-icon,
      .uncheck-square-icon {
        width: max-content;
        background: white;
        margin: 0;
        padding: 0;
        cursor: pointer;
        svg {
          padding: 0;
          margin: 0;
        }
      }
      .check-square-icon {
        svg {
          transform: scale(1.02);
        }
      }
    }
    .footer-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      .skip-btn {
        background: #ffffff;
        border: 1px solid #f2f4f7;
        /* Shadow/xs */

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: toRem(8px);
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: toRem(12px);
        line-height: toRem(20px);
        /* identical to box height, or 167% */
        padding: toRem(8px) toRem(12px);
        color: #667085;
      }
      .submit-btn {
        background: #ffffff;
        border: 1px solid #077789;
        /* Shadow/xs */
        padding: toRem(8px) toRem(12px);
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: toRem(8px);
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: toRem(12px);
        line-height: toRem(20px);
        /* identical to box height, or 167% */

        color: #077789;
      }
    }
  }
}

.additional-instructions-wrapper {
  width: 100%;
  margin-bottom: 0.5rem;
  .additional-instructions {
    box-sizing: border-box;
    background: var(--gray-100);
    border-radius: 0.5rem 0.5rem 0px 0px;
    margin: 1.125rem 0px 0px 0px;
    padding: 0.625rem;
    padding-left: 1rem;
    align-items: center;
    align-content: center;
    display: flex;
    height: 3rem;
  }
  .additional-instructions:hover,
  .additional-instructions:focus {
    outline: 1px solid var(--teal);
    .search-icon {
      color: var(--teal);
    }
  }

  .additional-instructions.Mui-focused {
    outline: 1px solid var(--teal);
    .search-icon {
      color: var(--teal);
    }
  }

  .additional-instructions-bottom-border {
    border-radius: 0.5rem;
  }

  .additional-instructions-redo-button {
    color: $gray-500;
    background: var(--gray-100);
    border: none;
    border-radius: $border-radius-standard;
    &:focus {
      outline: none;
    }
    .icon {
      background-color: transparent;
      padding: 0;
      svg {
        path {
          stroke: #667085;
        }
      }
    }
  }
}
