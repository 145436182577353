@import "@aptedge/lib-ui/src/styles/_colors";
@import "@aptedge/lib-ui/src/styles/_typography";

.cardContainer {
  display: grid;
  background: $viking-00;
  padding: 1rem;
  border-radius: 1rem;
  word-break: break-all;

  border-radius: $primary-border-radius;
  background-color: $slate-00;
  padding: 1rem;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    h6 {
      color: var(--Slate-Slate-60, #7789a4);
      /* Body/Medium/M */
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      margin-bottom: 0;
      margin-left: 0.5rem;
    }
  }
}
